import "./LogoImf.css";
import maps_logo from "../Footer/Images/map_pin.svg";
import map_dark from "../Footer/Images/map-pin (1).svg";
import footer_logo from "../Footer/Images/Neoteric-Technology-Logo.svg";
import NeotericTechFooterWhite from "../Footer/Images/NeotericTechFooterWhite.svg";

export const LogoImf = ({ Mode }) => {
  return (
    <div className="LogoContainer col-lg-4 col-md-12 col-sm-12 col-12">
      <img src={Mode ? NeotericTechFooterWhite : footer_logo} />

      <p style={Mode ? { color: "white" } : { color: "black" }}>
        <span>
          {" "}
          <img className="map_pin" src={Mode ? map_dark : maps_logo} />
        </span>
        # 55, VStarex IT HUB,Hitech City, Hyderabad, Telangana, India - 500072
      </p>
    </div>
  );
};
