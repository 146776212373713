// src/NotFound.jsx
import React from 'react';
import './NotFound.css';

const NotFound = () => {
  return (
    <div className="not-found">
      <h1>Oops, looks like the page is lost.</h1>
      <p>This is not a fault, just an accident that was not intentional.</p>
      <a href="/" className="home-link">Go to Home</a>
    </div>
  );
};

export default NotFound;
