import "./copyright.css";

import copyright from "./copyright.svg";
import darkcopyright from "./copyright_white.svg";

export const CopyRights = ({ Mode }) => {
  return (
    <div className={Mode ? "CopyContainerDark" : "CopyContainerLight"}>
      {" "}
      <a>
        Copyright{" "}
        <span>
          {Mode ? <img src={darkcopyright} /> : <img src={copyright} />}
        </span>
        Neoteric Technologies . All Rights Reserved
      </a>
    </div>
  );
};
