import React from "react";
import { Link } from "react-router-dom";
import { v4 } from "uuid";
import "./servicelinks.css";

const Links = [
  ,
  { path: "services", tittle: "Services" },
  { path: "industries", tittle: "Industries" },
  { path: "careers ", tittle: "Careers" },
  { path: "about", tittle: "About" },
  { path: "contact", tittle: "Contacts Us" }
];
export const ServiceLinks = ({ Mode }) => {
  return (
    <div className="serviceLinks col-lg-3 col-md-6 col-sm-12 col-12">
      <div className="footerheading">Quick Links</div>
      {Links.map(({ path, tittle }) => {
        return (
          <li key={v4()}>
            <Link
              to={path}
              className="serviceLinks-hover"
              style={Mode ? { color: "white" } : { color: "black" }}
            >
              {tittle}
            </Link>
          </li>
        );
      })}
    </div>
  );
};
