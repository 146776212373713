import React from "react";
import { v4 } from "uuid";
import "./socialLinks.css";
import darkfacebook from "./Images/facebook-logo.svg";
import darkinstagram from "./Images/instagram-logo.svg";
import darklinkedin from "./Images/linkedin-logo.svg";
import darkTwitter from "./Images/twitter-logo.svg";

import facebook from "./Images/facebook.svg";
import instagram from "./Images/instagram.svg";
import linkedin from "./Images/linkedin.svg";
import Twitter from "./Images/twitter.svg";

const Links = [
  {
    path: "https://www.facebook.com/profile.php?id=61557087178412",
    tittle: "Facebook",
    img: facebook,
    Dimg: darkfacebook,
  },
  {
    path: "https://twitter.com/InfoNeoteric/",
    tittle: "Twitter",
    img: Twitter,
    Dimg: darkTwitter,
  },
  {
    path: "https://www.linkedin.com/in/neoteric-technologies-748a742b5/",
    tittle: "Linkedin",
    img: linkedin,
    Dimg: darklinkedin,
  },
  {
    path: "https://www.instagram.com/neoterictechnologiesltd/",
    tittle: "Instagram",
    img: instagram,
    Dimg: darkinstagram,
  },
];
export const SocialLinks = ({ Mode }) => {
  return (
    <div className="socialLinks col-lg-3 col-md-6 col-sm-12 col-12">
      <div className="footerheading">Follow Us On</div>
      {Links.map(({ path, tittle, img, Dimg }) => {
        return (
          <li key={path}>
            <a
              href={path}
              key={path}
              id={path}
              target="_blank"
              style={Mode ? { color: "white" } : { color: "black" }}
            >
              <img src={Mode ? Dimg : img} />
              <span className="serviceLinks-hover">{tittle}</span>
            </a>
          </li>
        );
      })}
    </div>
  );
};
